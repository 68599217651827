import React from 'react';


import * as S from './ComingSoonSc';
import WorkingPeople from '@assets/img/working-people-code.jpg';
import LogoDark from '@assets/svg/logoDark.svg';

const ComingSoon: React.FC = () => {
    return (
        <>
            <S.Header>
                <S.HeaderLogo>
                    <img src={LogoDark} alt={'Bild von 3 Personen die Arbeiten'} loading='lazy' />
                </S.HeaderLogo>
            </S.Header>

            <S.Container>
                <S.ContentContainer>
                    <S.Title>In Kürze verfügbar</S.Title>

                    <S.Text>
                        Wir arbeiten mit Hochdruck an der Fertigstellung von ibendo.
                        <br />
                        Wir halten dich auf dem Laufenden.
                    </S.Text>
                </S.ContentContainer>

                <S.ImageContainer>
                    <img src={WorkingPeople} alt={'Bild von 3 Personen die Arbeiten'} loading='lazy' />
                </S.ImageContainer>
            </S.Container>
        </>
    );
};

export default ComingSoon;
