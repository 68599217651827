'use client';

import ThemeTypes from '@ibendo/style/ThemeType';
import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100vw - 30px);
    min-height: calc(100vh - 120px);
    height: auto;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 15px;

    @media (max-width: 900px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        margin-top: 120px;
    }

    @media (max-width: 600px) {
        margin-top: 80px;
        min-height: calc(100vh - 80px);
    }
`;

export const Title = styled.h1`
    font-size: 75px;
    color: ${ThemeTypes.color.dark};
    font-weight: 600;
    margin-bottom: 15px;

    @media (max-width: 900px) {
        text-align: center;
        font-size: 8vw;
    }
`;

export const Text = styled.p`
    font-size: ${ThemeTypes.text}px;
    color: ${ThemeTypes.color.dark};
    font-weight: 400;

    @media (max-width: 900px) {
        text-align: center;
        font-size: 4vw;
    }
`;

export const SmallLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 120px;
    padding: 0 15px;
    position: fixed;
    top: 0;
    z-index: 100;
    backdrop-filter: blur(5px);
    background-color: transparent;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0px 10px 15px -20px rgba(155,155,155,1);

    @media (max-width: 600px) {
        height: 80px;
    }
`;

export const HeaderLogo = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;
    height: 100%;

    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 600px) {
        width: 150px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 600px;
`;

export const ImageContainer = styled.div`
    width: 100%;
    max-width: 600px;
    min-height: 400px;
    height: 100%;
    margin-bottom: 15px;
    position: relative;

    img {
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 900px) {
        height: 300px;
        margin-bottom: 0px;
    }
`;
