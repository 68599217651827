import ComingSoon from '@ibendo/components/global/comingSoon';
// import Header from '../components/global/header';
// import Main from '../components/global/main';

function App() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default App;
